import {useState} from 'react';
import {Upload, Button} from 'antd';
import {UploadOutlined, DeleteOutlined} from '@ant-design/icons';
import {useTranslation} from 'react-i18next';
import HELPER_FUNCS from 'utils/helpers/funcs';

const FileUploadField = ({accept, ...props}: any) => {
	const {t} = useTranslation();
	const {editableFile, allowToDelete, ...rest} = props;
	const [fileList, setFileList] = useState<any>([]);
	const [uploadError, setUploadError] = useState<string | null>(null);

	const uploadProps = {
		onRemove: (file: any) => {
			setFileList((prevFileList: any) => {
				const index = prevFileList.indexOf(file);
				const newFileList = prevFileList.slice();
				newFileList.splice(index, 1);

				if (newFileList.length === 0) {
					if (rest.onChange) {
						rest.onChange(null);
					}
				}

				return newFileList;
			});
		},

		beforeUpload: (file: any) => {
			const fileExtension = `.${file.name.split('.').pop()}`;
			if (!accept.split(',').includes(fileExtension)) {
				setUploadError(t('wrongFormat', {acceptFiles: accept}));
				return false;
			} else if (!(file.size / 1024 / 1024 < 2)) {
				setUploadError(t('image2MB'));
				return false;
			} else if (props.maxCount > fileList.length) {
				setFileList([...fileList, {...file, name: HELPER_FUNCS.truncateText(file.name, 20)}]);
			} else {
				setUploadError(`Թույլատրվում է ${props.maxCount} ֆայլ`);
				return false;
			}
			setUploadError(null);

			return false;
		},
		fileList
	};

	const handleDelete = () => {
		setFileList([]);
		if (rest.onChange) {
			rest.onChange(null);
		}
	};

	return (
		<div style={{display: 'flex', flexDirection: 'column'}}>
			{(editableFile || typeof rest.value !== 'string') && (
				<>
					<Upload
						{...rest}
						{...uploadProps}
						style={{display: 'flex', flexDirection: 'column'}}
						className={rest.className ? rest.className : 'uploadBtnErr'}>
						<Button
							icon={<UploadOutlined />}
							type="dashed"
							className={props.className || undefined}>
							{props.className ? t('upload') : t('selectFile')}
						</Button>
					</Upload>
					<div className="upload-error" style={{color: 'red', marginTop: 20}}>
						{uploadError}
					</div>
				</>
			)}
			{typeof rest.value === 'string' && (
				<div style={{display: 'flex', alignItems: 'center', marginTop: 8}}>
					<a href={rest.value} target="_blank" rel="noreferrer">
						{rest.value.substr(rest.value.lastIndexOf('/') + 1)}
					</a>
					{allowToDelete && (
						<Button
							icon={<DeleteOutlined color="gray" />}
							type="link"
							onClick={handleDelete}
							style={{marginLeft: 8, color: 'gray'}}>
							{/* {t('delete')} */}
						</Button>
					)}
				</div>
			)}
		</div>
	);
};

export default FileUploadField;
