import {TranslationFuncType} from 'components/CRUDTable/types';
// @ts-ignore
import parsePhoneNumberFromString from 'libphonenumber-js';

export const numberRules = (t: TranslationFuncType) => ({
	validator: (_: any, value: number) =>
		!value || (!isNaN(value) && value >= 0 && String(value)?.length < 13)
			? Promise.resolve()
			: String(value)?.length > 12
			? Promise.reject(new Error(t('max12Characters')))
			: Promise.reject(new Error(t('numberInputOnly')))
});
export const passwordRules = (t: TranslationFuncType, showError: boolean = false) => ({
	pattern: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[ !"#$%&'()*+,./:;<=>?@[\]^_`{|}~£-]).{8,}$/,
	message: showError ? t('passwordCriteria') : ''
});
export const numberRulesAmount = (t: TranslationFuncType, amount: number) => ({
	validator: (_: any, value: number) =>
		!value || (!isNaN(value) && value >= 0 && value <= amount && String(value)?.length < 13)
			? Promise.resolve()
			: value > amount
			? Promise.reject(new Error(t('numberWithAmount', {amount: amount})))
			: String(value)?.length > 12
			? Promise.reject(new Error(t('max12Characters')))
			: Promise.reject(new Error(t('numberInputOnly')))
});

export const numberRulesAmountLittle = (t: TranslationFuncType, amount: number) => ({
	validator: (_: any, value: number) =>
		!value || (!isNaN(value) && value >= 0 && value >= amount && String(value)?.length < 13)
			? Promise.resolve()
			: value < amount
			? Promise.reject(new Error(t('numberWithAmountLittle', {amount: amount})))
			: String(value)?.length > 12
			? Promise.reject(new Error(t('max12Characters')))
			: Promise.reject(new Error(t('numberInputOnly')))
});

export const latinRules = (t: TranslationFuncType) => ({
	pattern: new RegExp('^[A-Za-z-\' /\\.,;:!?()"‘’“”]+$'),
	message: t('latinInputOnly')
});

export const maxLengthRules = (maxLength: number, t: TranslationFuncType) => ({
	max: maxLength,
	message: t('_100_simbols', {amount: maxLength})
});

export const minChars = (t: TranslationFuncType, minChars: number) => ({
	min: minChars,
	message: t('atLeastChars', {minChars: minChars})
});

export const latinRulesWithNumber = (t: TranslationFuncType) => ({
	pattern: new RegExp("^[A-Za-z-' 0-9 №#.:()*&^%$@,! ]{1,999}$"),
	message: t('latinInputOnly')
});

export const russianRules = (t: TranslationFuncType) => ({
	pattern: new RegExp("^[А-Яа-я-ё,Ё -' /]{1,999}$"),
	message: t('rusInputOnly')
});

export const russianRulesWithNumber = (t: TranslationFuncType) => ({
	pattern: new RegExp("^[А-Яа-я-ё Ё 0-9 №#.:()*&^%$@!/, -']{1,999}$"),
	message: t('rusInputOnly')
});

export const armenianRules = (t: TranslationFuncType) => ({
	pattern: new RegExp("^[Ա-Ֆա-ֆ-և՝,\\-–—՞«»‘’“”…։․՛<>\\s'` /]+$"),
	message: t('armInputOnly')
});

export const armenianRulesWithNumber = (t: TranslationFuncType) => ({
	pattern: new RegExp("^[Ա-Ֆա-ֆ-և 0-9 №#.:`()*&^%$@,/! -']{1,999}$"),
	message: t('armInputOnly')
});

export const linkRule = (t: TranslationFuncType) => ({
	pattern: new RegExp(
		/^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/
	),
	message: t('errorMessage_incorrectValue')
});

export const alphaNumeric = (t: TranslationFuncType) => ({
	pattern: new RegExp('^[A-Za-z0-9]{1,999}$'),
	message: t('onlyLetterOrNumber')
});

export const atLeastOneLetter = (t: TranslationFuncType) => ({
	pattern: new RegExp('/p{L}+/u'),
	message: t('errorMessage_incorrectValue')
});

export const armenianPhoneNumberRule = (t: TranslationFuncType) => ({
	pattern: new RegExp('^\\+374\\d{2}\\d{6}$'),
	message: t('armenianPhoneNumberPlus')
});

// export const internationalPhoneNumberRule = (t: TranslationFuncType) => ({
// 	validator: (_: any, value: string) => {
// 		const phoneNumber = parsePhoneNumberFromString(value);

// 		if (!value || (phoneNumber && phoneNumber.isValid())) {
// 			return Promise.resolve();
// 		} else {
// 			return Promise.reject(new Error(t('phoneNumberError')));
// 		}
// 	}
// });
export const internationalPhoneNumberRule = (t: TranslationFuncType) => ({
	validator: (_: any, value: string) => {
		// If the value is empty, resolve the promise without any errors.
		if (!value) {
			return Promise.resolve();
		}

		// If the value is not empty, perform phone number validation.
		const phoneNumber = parsePhoneNumberFromString(value);

		if (phoneNumber && phoneNumber.isValid()) {
			return Promise.resolve();
		} else {
			return Promise.reject(new Error(t('phoneNumberError')));
		}
	}
});

export const numberOnlyRule = (t: TranslationFuncType) => ({
	pattern: new RegExp('^\\d+$'), // This regex matches any sequence of digits (numbers).
	message: t('numberInputOnly') // Replace 'yourErrorMessageKey' with the key of your error message in your translation file.
});
export const decimalNumberOnlyRule = (t: TranslationFuncType) => ({
	pattern: new RegExp('^(\\d+(\\.\\d{1,2})?)?$'),
	message: t('decimalNumberInputOnly')
});

export const withoutSpacesRule = (t: TranslationFuncType) => ({
	pattern: new RegExp('^\\d+$'),
	message: t('withoutSpaces')
});
export const maxCharLimit = (maxLength: number, t: any) => ({
	validator: (_: any, value: any) =>
		!value || String(value).length <= maxLength
			? Promise.resolve()
			: Promise.reject(new Error(t('_100_simbols', {amount: maxLength})))
});
