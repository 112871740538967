import {ModalFormFieldTypes, TranslationFuncType} from 'components/CRUDTable/types';
interface FieldsType {
	(t: TranslationFuncType, setFieldFunc: any): ModalFormFieldTypes[];
}
export const loginByEmailFields: FieldsType = (t: TranslationFuncType, setFieldFunc: any) => {
	return [
		{
			placeHolder: t('email'),
			name: 'email',
			title: t('email'),
			type: 'text',
			className: 'regLayout__main_formContainer_form_input',
			rules: [{type: 'email'}],
			colProps: {
				xs: 24
			}
		},
		{
			type: 'customElement',
			component: () => {
				return (
					<p
						style={{cursor: 'pointer', textDecoration: 'underline', textAlign: 'center'}}
						onClick={() => setFieldFunc('LOGIN')}>
						{t('back')}
					</p>
				);
			}
		}
	];
};
