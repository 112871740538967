enum API {
	LOGIN = '/v1/admins/auth/sign-in',
	LOGOUT = '/v1/auth/sign-out',
	LOGIN_BY_EMAIL = '/v1/admins/auth/sign-in',
	SIGN_IN_WITH_EMAIL = '/v1/admins/auth/sign-in/email',
	// REGISTER = '/auth/register',
	// CONFIRM = '/auth/confirm',
	SET_PASSWORD = '/v1/admins/auth/set-password',
	VERIFY_SIGN_IN_WITH_EMAIL_OTP = 'v1/admins/auth/sign-in/otp'
}

export default API;
