import axiosClient from 'utils/axiosClient';
import api from 'api';
import {formData} from 'utils/helpers/formData';

const getCarriers = async (params: any) => {
	const response = await axiosClient.get(api.CARRIERS.CARRIERS, {params});
	return response.data;
};

const getCarrierById = async (id: any) => {
	const response = await axiosClient.get(api.CARRIERS.CARRIERS + '/' + id);
	return response.data;
};

const getBalance = async (id: number) => {
	const response = await axiosClient.get(api.CARRIERS.CARRIERS + `/${id}/balance`);
	return response.data;
};

const createCarrier = async (data: any) => {
	const response = await axiosClient.post(api.CARRIERS.CARRIERS, data);
	return response.data;
};
// TODO WILL BE REMOVED
// const deleteCarrier = async (data: any) => {
// 	const response = await axiosClient.delete(api.CARRIERS.CARRIERS, {data});
// 	return response.data;
// };

const updateCarrier = async (data: any, id: any) => {
	const response = await axiosClient.patch(api.CARRIERS.CARRIERS + '/' + id, data);
	return response.data;
};
const deattachCarrierFromUser = async (data: any, id: any) => {
	const response = await axiosClient.patch(api.CARRIERS.CARRIERS + '/' + id, data);
	return response.data;
};
const syncCarrier = async () => {
	const response = await axiosClient.post(api.CARRIERS.CARRIERS + '/sync');
	return response.data;
};

const syncCarrierById = async (id: number) => {
	const response = await axiosClient.post(api.CARRIERS.CARRIERS + `/sync/${id}`);
	return response.data;
};

const uploadCarrierList = async (data: any) => {
	const response = await axiosClient.post(api.CARRIERS.CARRIERS + '/upload', formData(data));
	return response.data;
};

const service = {
	getCarriers,
	getCarrierById,
	createCarrier,
	getBalance,
	deattachCarrierFromUser,
	// deleteCarrier,
	updateCarrier,
	syncCarrier,
	syncCarrierById,
	uploadCarrierList
};

export default service;
