import React, {useState, useEffect} from 'react';
import {Input, DatePicker, Rate, TimePicker, Checkbox} from 'antd';
import FileUploadField from '../FileUploadField';
import SelectField from '../SelectField';
import RadioField from '../RadioField';
import moment from 'moment-timezone';
import {memo} from 'react';
import _ from 'lodash';
import {useTranslation} from 'react-i18next';

const CaseComponent = ({
	type,
	inputProps,
	datePickerProps,
	rangePickerProps,
	weekPickerProps,
	selectProps,
	className,
	uploadProps,
	rateProps,
	radioProps,
	checkboxProps,
	textAreaProps,
	checkboxGroupProps,
	getFieldValue,
	searchProps,
	...rest
}: any) => {
	const [timeSelected, setTimeSelected] = useState(false);
	const [mode, setMode] = useState('date'); // Initialize mode state with 'date'
	const [formattedPhoneValue, setFormattedPhoneValue] = useState('');
	const [passwordValue, setPasswordValue] = useState('');
	const [open, setOpen] = useState(false);
	const [criteria, setCriteria] = useState({
		minLength: false,
		hasUpperCase: false,
		hasNumber: false,
		hasSpecialChar: false,
		haslowerCase: false
	});
	const [showCriteria, setShowCriteria] = useState(false);
	useEffect(() => {
		// Pre-process the initial value to remove the "+" if present
		if (type === 'phone' && rest.value) {
			const newValue = rest.value.startsWith('+') ? rest.value.replace(/^\+/, '') : rest.value;
			setFormattedPhoneValue(newValue);
		}
	}, [rest.value, type]);

	const handlePanelChange = (value: any, newMode: any) => {
		setMode(newMode); // Update mode state when panel changes
	};

	const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = e.target;
		// Check if the value is a valid phone number and modify it
		if (/^\+?\d*$/.test(value)) {
			// Remove all non-digit characters
			const formattedValue = value.replace(/\D/g, '');
			// Add "+" sign if not already present
			const newValue = formattedValue.startsWith('+') ? formattedValue : `+${formattedValue}`;
			setFormattedPhoneValue(formattedValue);
			// Update the input value
			rest.onChange(newValue);
			if (newValue === '+') {
				setFormattedPhoneValue('');
				rest.onChange('');
			} else {
				setFormattedPhoneValue(newValue);
				rest.onChange(newValue);
			}
		}
	};
	const handleOpenChange = (status: any) => {
		setOpen(status);
	};

	const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = e.target;
		setCriteria({
			minLength: value.length >= 8,
			hasUpperCase: /[A-Z]/.test(value),
			hasNumber: /[0-9]/.test(value),
			hasSpecialChar: /[!@#$%^&*+,./:;<=>?@[\]^_`{|}~£-]/.test(value),
			haslowerCase: /[a-z]/.test(value)
		});
		setPasswordValue(value);
		rest.onChange(value);
	};
	let {t} = useTranslation();

	switch (type) {
		case 'text':
			return <Input {...rest} {...inputProps} className={className} />;
		case 'password':
			return (
				<>
					<Input.Password
						{...rest}
						{...inputProps}
						className={className}
						value={passwordValue}
						onClick={() => setShowCriteria(true)}
						onChange={handlePasswordChange}
					/>
					{rest.showCriteria && (
						<ul className={`password-criteria ${showCriteria ? 'visible' : ''}`}>
							<li className={criteria.minLength ? 'valid' : ''}>{t('passwordAtLeastChars')}</li>
							<li className={criteria.hasUpperCase ? 'valid' : ''}>
								{t('passwordAtLeastCharsCapitalLetter')}
							</li>
							<li className={criteria.hasNumber ? 'valid' : ''}>
								{t('passwordAtLeastOneNumber')}
							</li>
							<li className={criteria.hasSpecialChar ? 'valid' : ''}>
								{t('passwordAtLeastOneSpecialCharacter')}
							</li>
							<li className={criteria.haslowerCase ? 'valid' : ''}>
								{t('passwordAtLeastOneSmallLetter')}
							</li>
						</ul>
					)}
				</>
			);
		case 'textArea':
			return <Input.TextArea {...rest} {...textAreaProps} />;
		case 'phone': // Modified case for phone input
			return (
				<Input {...rest} {...inputProps} value={formattedPhoneValue} onChange={handlePhoneChange} />
			);
		case 'date':
			if (datePickerProps?.picker === 'time') {
				return (
					<TimePicker
						{...rest}
						{...datePickerProps}
						value={rest.value && moment.utc(rest.value, 'HH:mm')}
						style={{width: '100%'}}
						disabled={timeSelected} // Disable the TimePicker if time has been selected
						onChange={selectedTime => {
							// Update the state to indicate that time has been selected
							setTimeSelected(true);
							// Additional logic if needed
							// Pass the selected time to the parent component or perform any other action
							rest.onChange(selectedTime);
						}}
					/>
				);
			}
			return (
				<DatePicker
					showTime
					placeholder={datePickerProps?.format ? datePickerProps?.format : 'DD-MM-YYYY'}
					format={datePickerProps?.format ? datePickerProps?.format : 'DD-MM-YYYY'}
					{...rest}
					{...datePickerProps}
					onOpenChange={handleOpenChange}
					disabledDate={
						typeof datePickerProps?.disabledDateBy === 'function'
							? date => datePickerProps?.disabledDateBy?.(date, getFieldValue)
							: datePickerProps?.disabledDate
					}
					value={
						rest.value
							? rest.name === 'createdAt' ||
							  rest.name === 'transactionDate' ||
							  rest.name === 'periodStartDate' ||
							  rest.name === 'periodEndDate'
								? moment(rest?.value?.split('_')[0])
								: moment(rest.value)
							: null
					}
					open={open}
					onClick={() => setOpen(true)}
					style={{width: '100%'}}
				/>
			);
		case 'dateRange':
			return <DatePicker.RangePicker {...rest} {...rangePickerProps} style={{width: '100%'}} />;
		case 'weekPicker':
			return (
				<DatePicker
					placeholder=""
					{...rest}
					{...weekPickerProps}
					value={rest.value ? moment(rest.value) : null}
					disabledDate={
						typeof weekPickerProps?.disabledDateBy === 'function'
							? date => weekPickerProps?.disabledDateBy?.(date, getFieldValue)
							: weekPickerProps?.disabledDate
					}
					picker="week"
					onOpenChange={handleOpenChange}
					open={open}
					onClick={() => setOpen(true)}
					style={{width: '100%'}}
				/>
			);
		case 'select':
			return <SelectField {...rest} {...selectProps} />;
		case 'search':
			return <Input.Search {...rest} {...searchProps} />;
		case 'file':
			return <FileUploadField {...rest} {...uploadProps} className={className} />;
		case 'rate':
			return <Rate {...rest} {...rateProps} character={({index}: {index: number}) => index + 1} />;
		case 'radio':
			return <RadioField {...rest} {...radioProps} />;
		case 'checkbox':
			return <Checkbox {...rest} {...checkboxProps} />;
		case 'checkboxGroup':
			return <Checkbox.Group {...rest} {...checkboxGroupProps} />;
		case 'color':
			return <Input {...rest} {...inputProps} type="color" />;

		default:
			return null;
	}
};

export default memo(CaseComponent, (prev: any, newState: any) => {
	let bool: boolean = true;

	if (bool) {
		bool = prev.value === newState.value;
	}

	if (bool) {
		bool = prev.disabled === newState.disabled;
	}

	if (_.has(prev, 'checked') && bool) {
		bool = prev.checked === newState.checked;
	}

	if (_.has(prev, 'fetchParams') && bool) {
		bool = !!!Object.keys(newState).length;
	}
	return bool;
});
