import axiosClient from 'utils/axiosClient';
import api from 'api';

const getPayments = async (params?: any) => {
	const response = await axiosClient.get(api.PAYMENTS.PAYMENTS, {params});
	return response.data;
};

const getPaymentById = async (id: any) => {
	const response = await axiosClient.get(api.PAYMENTS.PAYMENTS + '/' + id);
	return response.data;
};

const createPayment = async (data: any) => {
	const response = await axiosClient.post(api.PAYMENTS.PAYMENTS, data);
	return response.data;
};

const deletePayment = async (data: any) => {
	const response = await axiosClient.delete(api.PAYMENTS.PAYMENTS, {data});
	return response.data;
};

const updatePayment = async (data: any, id: any) => {
	const response = await axiosClient.patch(api.PAYMENTS.PAYMENTS + '/' + id, data);
	return response.data;
};

const service = {
	getPayments,
	getPaymentById,
	createPayment,
	deletePayment,
	updatePayment
};

export default service;
