import axios from 'axios';
import Toast from 'components/Toast';
import i18next, {t} from 'i18next';
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const axiosClient = axios.create({
	headers: {
		'Content-Type': 'application/json'
	}
});

axiosClient.interceptors.request.use(function (config) {
	const token = localStorage.getItem('token');
	if (token && config.headers) {
		config.headers.Authorization = 'Bearer ' + token;
		config.headers['Accept-Language'] = config.headers['Accept-Language'] || i18next.language;
	}

	return config;
});

axiosClient.interceptors.response.use(
	response => response,
	error => {
		if (error.response) {
			// The request was made and the server responded with a status code
			// that falls out of the range of 2xx
			if (
				(error.response.data.statusCode === 401 && error.response.data.code === 5) ||
				(error.response.data.statusCode === 403 && error.response.data.code === 3)
			) {
				window.location.href = '/';
				localStorage.clear();
			}
			if (
				(error.response.status === 400 ||
					error.response.status === 404 ||
					error.response.status === 403 ||
					error.response.data.statusCode === 409) &&
				typeof error.response.data.message === 'string'
			) {
				Toast.error(t(`messages.code.${error.response.data.code}`));
			}
		} else if (error.request) {
			// The request was made but no response was received
			// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
			// http.ClientRequest in node.js
			console.error(error);
		} else {
			// Something happened in setting up the request that triggered an Error
			console.error('Error on request');
		}

		return Promise.reject(error.response.data);
	}
);

export default axiosClient;
