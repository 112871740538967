import {createContext, ReactNode} from 'react';

export interface InnerTableProps {
	isInnerTable: boolean;
	visible: 'ADD' | 'EDIT' | 'VIEW' | 'NEVER' | undefined;
}

interface InitContextProps {
	state: InnerTableProps;
}

export const InnerTableContext = createContext({} as InitContextProps);

const InnerTableProvider = ({children, value}: {children: ReactNode; value: InnerTableProps}) => {
	return <InnerTableContext.Provider value={{state: value}}>{children}</InnerTableContext.Provider>;
};

export default InnerTableProvider;
