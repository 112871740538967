import {ModalFormFieldTypes, TranslationFuncType} from 'components/CRUDTable/types';
import {passwordRules} from 'utils/formRules';

interface FieldsType {
	(t: TranslationFuncType, setFieldFunc?: any): ModalFormFieldTypes[];
}

export const resetPasswordFields: FieldsType = t => [
	{
		name: 'password',
		label: t('currentnewPassword'),
		type: 'password',
		className: 'passwordInput regLayout__main_formContainer_form_input',

		showCriteria: true,
		autoComplete: 'new-password',
		rules: [passwordRules(t, false)],
		inputProps: {
			autoComplete: 'new-password'
		},
		colProps: {
			xs: 24
		}
	},
	{
		name: 'rePassword',
		className: 'passwordInput regLayout__main_formContainer_form_input',

		label: t('repetnewpassword'),
		autoComplete: 'new-password',
		type: 'password',
		inputProps: {
			autoComplete: 'new-repassword'
		},
		dependencies: ['password'],
		rules: [
			({getFieldValue}: {getFieldValue: (name: any) => any}) => ({
				validator(_: any, value: any) {
					if (value !== getFieldValue('password')) {
						return Promise.reject(new Error(t('passwordNotMatch')));
					}
					return Promise.resolve();
				}
			})
		],
		colProps: {
			xs: 24
		}
	}
];
