import {ModalFormFieldTypes, TranslationFuncType} from 'components/CRUDTable/types';

interface FieldsType {
	(t: TranslationFuncType, setFieldFunc: any): ModalFormFieldTypes[];
}
export const loginFields: FieldsType = (t: TranslationFuncType, setFieldFunc: any) => {
	return [
		{
			placeHolder: t('email'),
			name: 'email',
			type: 'text',
			className: 'regLayout__main_formContainer_form_input',
			rules: [{type: 'email'}],
			colProps: {
				xs: 24
			}
		},
		{
			placeHolder: t('password'),
			name: 'password',
			type: 'password',
			className: 'regLayout__main_formContainer_form_input',
			rules: [{type: 'password'}],
			colProps: {
				xs: 24
			}
		},
		{
			type: 'customElement',
			component: () => {
				return (
					<div>
						<p
							style={{cursor: 'pointer', textDecoration: 'underline', textAlign: 'center'}}
							onClick={() => setFieldFunc('LOGIN_BY_EMAIL')}>
							{t('signInWithEmail')}
						</p>
					</div>
				);
			}
		}
	];
};
