import {useEffect, createContext, useReducer, Dispatch, ReactNode} from 'react';

const initialState: any = {permissions: {get: 1, post: 1, patch: 1}};

// export interface any {
// 	isAuthenticated: boolean | undefined;
// 	token: null | string;
// 	id: null | string;
// 	privilegeId: null | string;
// 	userTypeID: null | string;
// 	name: null | string;
// 	lastName: null | string;
// 	email: null | string;
// }

interface InitContextProps {
	state: any;
	dispatch: any;
}

export const PermissionContext = createContext({} as InitContextProps);

const reducer = (state: any, action: any) => {
	switch (action.type) {
		case 'SET_PERMISSIONS':
			return {
				...state,
				permissions: action.payload
			};

		default:
			return state;
	}
};

const PermissionProvider = ({children}: {children: ReactNode}) => {
	const [state, dispatch] = useReducer(reducer, initialState);
	const value = {state, dispatch};

	// useEffect(() => {
	// 	const token = localStorage.getItem('token');
	// 	const name = localStorage.getItem('name');
	// 	const lastName = localStorage.getItem('lastName');
	// 	const email = localStorage.getItem('email');
	// 	const id = localStorage.getItem('id');
	// 	const privilegeId = localStorage.getItem('privilegeId');
	// 	const userTypeID = localStorage.getItem('userTypeID');

	// 	if (token && name && lastName && email && id && privilegeId && userTypeID) {
	// 		dispatch({
	// 			type: 'LOGIN',
	// 			payload: {
	// 				token,
	// 				name,
	// 				lastName,
	// 				email,
	// 				id,
	// 				privilegeId,
	// 				userTypeID
	// 			}
	// 		});
	// 	} else {
	// 		dispatch({
	// 			type: 'LOGOUT'
	// 		});
	// 	}
	// }, []);
	return <PermissionContext.Provider value={value}>{children}</PermissionContext.Provider>;
};

export default PermissionProvider;
