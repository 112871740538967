import axiosClient from 'utils/axiosClient';
import api from 'api';

interface LoginTypes {
	email: string;
	password: string;
}

interface RegTypes {}

const login = async (data: LoginTypes) => {
	const response = await axiosClient.post(api.AUTH.LOGIN, data);
	return response.data;
};

const logInWithEmail = async (data: any) => {
	const res = await axiosClient.post(api.AUTH.SIGN_IN_WITH_EMAIL, data);
	return res?.data;
};

const signInWithEmailOtp = async (data: any) => {
	const res = await axiosClient.post(api.AUTH.VERIFY_SIGN_IN_WITH_EMAIL_OTP, data);
	return res?.data;
};

const logout = async () => {
	const response = await axiosClient.post(api.AUTH.LOGOUT);
	return response.data;
};

const setPassword = async (data: any) => {
	const res = await axiosClient.post(api.AUTH.SET_PASSWORD, data);
	return res.data;
};

// const register = async (data: RegTypes) => {
// 	const response = await axiosClient.post(api.AUTH.REGISTER, data);
// 	return response.data;
// };

// const confirm = async (token: string) => {
// 	const response = await axiosClient.post(api.AUTH.CONFIRM, token);
// 	return response.data;
// };

// const resetPassword = async (token: string, data: {password: string; c_password: string}) => {
// 	const response = await axiosClient.post(api.AUTH.RESET_PASSWORD + token, data);
// 	return response.data;
// };

const service = {
	login,
	logout,
	// register,
	confirm,
	setPassword,
	logInWithEmail,
	signInWithEmailOtp
};

export default service;
