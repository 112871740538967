import {paths} from 'App';
import {Button, Layout, Menu, MenuProps} from 'antd';
import Logo from 'assets/mainLogo (2).svg';
import {SettingsContext} from 'context/Settings.context';
import {useLocalStorage} from 'hooks';
import {
	AppWindow,
	Clock,
	ClockCounterClockwise,
	File,
	// 	Bank,
	// 	BookOpen,
	// 	CalendarCheck,
	// 	File,
	// 	GraduationCap,
	// 	House,
	List,
	ListDashes,
	PresentationChart,
	Ticket,
	TrafficSignal,
	UserGear,
	Users,
	Wallet
	// 	Note,
	// 	Users,
	// 	UsersThree
} from 'phosphor-react';
import {useContext, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router-dom';
import {RouteProps} from 'router';
import i18n from 'utils/i18n';
import './Sider.less';
import {settingsPagesIds} from 'pages/Settings/Dictionaries/Constants';

const {Sider} = Layout;

// const paths = {
// 	1: '/',
// 	3: '/users/list'
// };

const icons = [
	{
		id: 1,
		icon: <PresentationChart size={24} weight="fill" />
	},
	{
		id: 2,
		icon: <Users size={24} weight="fill" />
	},
	{
		id: 7,
		icon: <UserGear size={24} weight="fill" />
	},
	{
		id: 13,
		icon: <Clock size={24} weight="fill" />
	},
	{
		id: 16,
		icon: <Ticket size={24} weight="fill" />
	},
	{
		id: 19,
		icon: <AppWindow size={24} weight="fill" />
	},
	{
		id: 20,
		icon: <ListDashes size={24} weight="fill" />
	},
	{
		id: 22,
		icon: <TrafficSignal size={24} weight="fill" />
	},
	{
		id: 21,
		icon: <Wallet size={24} weight="fill" />
	},
	// {
	// 	id: 24,
	// 	icon: <Receipt size={24} weight="fill" />
	// },
	// {
	// 	id: 25,
	// 	icon: <Files size={24} weight="fill" />
	// },
	// {
	// 	id: 26,
	// 	icon: <File size={24} weight="fill" />
	// }
	{
		id: 27,
		icon: <ClockCounterClockwise size={24} weight="fill" />
	}
];

const SiderMenu = () => {
	const [collapsed, setCollapsed] = useLocalStorage('sider_collapsed', false);
	const [openKeys, setOpenKeys] = useState<string[]>([]);
	const navigate = useNavigate();
	const {t} = useTranslation();
	const location = useLocation();
	const choosenPage = location.pathname;

	const {state: settingsState} = useContext(SettingsContext);
	const getMenuItems = (routes: RouteProps[]) => {
		const menu = routes
			.sort()
			.filter((el: any) => !settingsPagesIds.includes(el.id))
			.map(item => {
				if (item.children && item.children.length) {
					return (
						<Menu.SubMenu
							key={'sub-' + item.id}
							disabled={item.disabled}
							className="menuItem"
							// @ts-ignore
							title={item[i18n.language]}
							icon={icons.find(el => el.id === item.id)?.icon}>
							{getMenuItems(item.children)}
						</Menu.SubMenu>
					);
				}
				return (
					<Menu.Item
						className="menuItem"
						key={item.id}
						disabled={item.disabled}
						// @ts-ignore
						title={item[i18n.language]}
						icon={icons.find(el => el.id === item.id)?.icon}
						onClick={() => {
							// const capitalized =
							// 	// @ts-ignore
							// 	item.resource.charAt(0).toUpperCase() + item.resource.slice(1);
							// console.log(capitalized);
							// let newResource = item.resource;
							// @ts-ignore
							navigate(paths[item.id]?.path ?? '');
						}}>
						{/* @ts-ignore */}
						{item[i18n.language]}
					</Menu.Item>
				);
			});

		return menu;
	};
	const menu = useMemo(() => getMenuItems(settingsState.menuItems), [location.pathname, i18n.language]);

	const onOpenChange: MenuProps['onOpenChange'] = (keys: string[]) => {
		const latestOpenKey = keys.find((key: string) => openKeys.indexOf(key) === -1);
		if (keys.indexOf(latestOpenKey!) === -1) {
			setOpenKeys(keys);
		} else {
			setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
		}
	};

	useEffect(() => {
		if (choosenPage === '/settings') {
			setCollapsed(() => true);
		}
	}, [choosenPage]);

	return (
		<Sider trigger={null} collapsible collapsed={collapsed} className="sider" width={282}>
			<div
				className="logo-container"
				style={
					collapsed
						? {
								flexDirection: 'column-reverse',
								justifyContent: 'center',
								height: 100,
								rowGap: 15
						  }
						: {}
				}>
				<img
					src={Logo}
					alt="Logo"
					style={{maxWidth: collapsed ? 70 : undefined}}
					onClick={() => {
						navigate('/');
					}}
					height={collapsed ? 33 : 42}
				/>
				<Button
					onClick={() => setCollapsed(prev => !prev)}
					type="text"
					icon={<List size={24} weight="fill" />}
				/>
			</div>
			<Menu
				className="menu"
				mode="inline"
				defaultSelectedKeys={[choosenPage]}
				openKeys={openKeys}
				onOpenChange={onOpenChange}
				selectedKeys={[choosenPage]}>
				{menu}
			</Menu>
		</Sider>
	);
};

export default SiderMenu;
