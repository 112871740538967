import {useState, useContext, useEffect} from 'react';
import {Form, Input, Button} from 'antd';
import {AuthContext} from 'context/Auth.context';
import {useLocation, useNavigate} from 'react-router-dom';
import moment from 'moment';
import services from 'services';
import {useTranslation} from 'react-i18next';
import './styles.less';

interface InputErrorsTypes {
	password: string;
	c_password: string;
}

interface UserTypes {
	isSubmitting: boolean;
	inputErrors: InputErrorsTypes | null;
}

const ResetPassword = () => {
	const {t} = useTranslation();
	const [user, setUser] = useState<UserTypes>({
		isSubmitting: false,
		inputErrors: null
	});
	const {dispatch} = useContext(AuthContext);
	const navigate = useNavigate();
	const location = useLocation() as any;

	const token = new URLSearchParams(location.search).get('token');

	const onFinish = (values: any) => {
		if (!token) return;
		setUser({
			isSubmitting: true,
			inputErrors: null
		});
		// services.AUTH.resetPassword(token, values)
		// 	.then(() => {
		// 		navigate('/login', {replace: true});
		// 	})
		// 	.catch(err => {
		// 		let errMessage = null;
		// 		if (typeof err.message !== 'string') {
		// 			errMessage = err.message;
		// 		}
		// 		setUser({
		// 			isSubmitting: false,
		// 			inputErrors: errMessage
		// 		});
		// 	});
	};

	useEffect(() => {
		if (!token) {
			navigate('/login');
		}
	}, [token]);

	return (
		<div className="regLayout">
			<div className="regLayout__header">
				<div className="regLayout__header-logo"></div>
				<div className="regLayout__header-menu"></div>
			</div>
			<div className="regLayout__main">
				<div className="regLayout__main_formContainer">
					<div className="regLayout__main_formContainer_title">
						<span>{t('changePassword')}</span>
						<div id="divider" />
					</div>
					<Form
						className="regLayout__main_formContainer_form"
						layout="vertical"
						onFinish={onFinish}
						autoComplete="off">
						<Form.Item
							name="password"
							validateStatus={user.inputErrors?.password ? 'error' : undefined}
							help={user.inputErrors?.password}
							rules={[{required: true, message: 'Please input your password!'}]}>
							<Input.Password
								placeholder={t('password')}
								className="regLayout__main_formContainer_form_input"
							/>
						</Form.Item>

						<Form.Item
							name="c_password"
							validateStatus={user.inputErrors?.c_password ? 'error' : undefined}
							help={user.inputErrors?.c_password}
							rules={[
								{required: true, message: 'Please confirm your password!'},
								({getFieldValue}) => ({
									validator(_, value) {
										if (!value || getFieldValue('password') === value) {
											return Promise.resolve();
										}
										return Promise.reject(new Error(t('passwordNotMatch') as string));
									}
								})
							]}>
							<Input.Password
								placeholder={t('passwordRepeat')}
								className="regLayout__main_formContainer_form_input"
							/>
						</Form.Item>

						<Form.Item style={{textAlign: 'center'}}>
							<Button
								className="regLayout__main_formContainer_form_button"
								type="primary"
								htmlType="submit"
								loading={user.isSubmitting}>
								{t('changePassword')}
							</Button>
						</Form.Item>
					</Form>
				</div>
			</div>
			<div className="regLayout__footer">
				© {moment().year()}, Footer {/* {appConfigs.footerText || t('footer_university')} */}
			</div>
		</div>
	);
};

export default ResetPassword;
