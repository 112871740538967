import {ModalFormFieldTypes, TranslationFuncType} from 'components/CRUDTable/types';
import {maxCharLimit, minChars, numberOnlyRule} from 'utils/formRules';

interface FieldsType {
	(t: TranslationFuncType, setFieldFunc: any): ModalFormFieldTypes[];
}

export const otpConfirmationSignInByEmail: FieldsType = (t: TranslationFuncType, setFieldFunc: any) => [
	{
		type: 'customElement',
		component: () => {
			return (
				<div className="auth-component__forgotPass" style={{marginBottom: 10}}>
					<div className="auth-component__forgotPass-main">{t('pleaseVerifyYourEmail')}</div>
				</div>
			);
		},
		colProps: {
			xs: 24
		}
	},
	{type: 'newLine'},
	{
		placeHolder: t('verificationCode'),
		name: 'otp',
		className: 'regLayout__main_formContainer_form_input',

		type: 'text',
		rules: [numberOnlyRule(t), maxCharLimit(6, t), minChars(t, 6)],
		colProps: {
			xs: 24
		}
	},
	{
		type: 'customElement',
		component: () => {
			return (
				<div className="auth-component__forgotPass">
					<div
						style={{cursor: 'pointer', textDecoration: 'underline', textAlign: 'center'}}
						className="auth-component__forgotPass-main"
						onClick={() => setFieldFunc('LOGIN')}>
						{t('back')}
					</div>
				</div>
			);
		},
		colProps: {
			xs: 24
		}
	}
	// {
	// 	type: 'customElement',
	// 	component: () => {
	// 		return (
	// 			<div className="auth-component__forgotPass">
	// 				<div className="auth-component__forgotPass-main">Resend Code</div>
	// 			</div>
	// 		);
	// 	},
	// 	colProps: {
	// 		xs: 24
	// 	}
	// }
];
